// Generated by Framer (16060ad)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {iBA1FfypI: {hover: true}};

const cycleOrder = ["iBA1FfypI"];

const variantClassNames = {iBA1FfypI: "framer-v-16b8y7z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "iBA1FfypI", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iBA1FfypI", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-gExlk", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-16b8y7z", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iBA1FfypI"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"iBA1FfypI-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-hnnfob"} data-framer-name={"home_logo_vsei"} fill={"black"} intrinsicHeight={70} intrinsicWidth={160} layoutDependency={layoutDependency} layoutId={"UGi2j_AXr"} style={{opacity: 0.5}} svg={"<svg data-name=\"Component 848 – 1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"160\" height=\"70\"><g data-name=\"1920_home_std_newsletter\" fill=\"#666\" fill-rule=\"evenodd\"><path d=\"M85.134 25.093a3.816 3.816 0 1 1 0-1.966H97.44l4.272-10.307H77.476l-4.921 11.864H60.007L56.071 34.3h25.081a3.816 3.816 0 1 1 0 1.966H55.267l-3.333 8.143H64.45l-4.9 12.774h24.26l3.981-10.4H77.218a3.816 3.816 0 1 1 0-1.966h22.776l8.073-19.725Z\"/><path d=\"M73.533 43.843a1.958 1.958 0 1 0 1.958 1.958 1.96 1.96 0 0 0-1.958-1.958m11.303-10.521a1.958 1.958 0 1 0 1.958 1.958 1.961 1.961 0 0 0-1.958-1.958m-3.389-11.17a1.957 1.957 0 1 0 1.958 1.957 1.96 1.96 0 0 0-1.958-1.957\"/></g></svg>"} transition={transition} variants={{"iBA1FfypI-hover": {opacity: 1}}} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-gExlk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gExlk .framer-1ehv6qf { display: block; }", ".framer-gExlk .framer-16b8y7z { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 160px; }", ".framer-gExlk .framer-hnnfob { aspect-ratio: 2.2857142857142856 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); position: relative; width: 100%; }", ".framer-gExlk .framer-v-16b8y7z .framer-16b8y7z { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gExlk .framer-16b8y7z { gap: 0px; } .framer-gExlk .framer-16b8y7z > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-gExlk .framer-16b8y7z > :first-child { margin-left: 0px; } .framer-gExlk .framer-16b8y7z > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"ZYovjPFaB":{"layout":["fixed","auto"]}}}
 */
const FramernX54WmIqH: React.ComponentType<Props> = withCSS(Component, css, "framer-gExlk") as typeof Component;
export default FramernX54WmIqH;

FramernX54WmIqH.displayName = "logo-vsei";

FramernX54WmIqH.defaultProps = {height: 70, width: 160};

addFonts(FramernX54WmIqH, [])